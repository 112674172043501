<template>
    <v-container fluid class="pt-0 mr-10 ml-10">
        <v-layout row wrap>
            <v-col cols="9">
                <!-- Page view -->
                <router-view :key="reloadPage" :applyFilters="applyFilters" @properties-count="propertiesCount"></router-view>
                <!-- end -->
            </v-col>
            <v-col class="mt-12">
                <PropertiesFilters block="properties" :countingProperties="countingProperties" @filter-selected="filterSelected" 
                class="mt-16 mr-12"/>
            </v-col>
        </v-layout>
    </v-container>
</template>

<script>
  export default {
    name: 'Properties',
    data() {
      return {
        applyFilters: null,
        reloadPage: 0,
        countingProperties: 0
      }
    },
    methods:{
        filterSelected(val) {
            this.reloadPage += 1
            this.applyFilters = val
        },
        propertiesCount(v){
          this.countingProperties = v
        }
    }
  }
</script>
